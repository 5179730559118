import React from "react";
import { Select, Input, InputNumber, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import qs from "../../utils/qs";

import './css/index.sass'

export default class SearhModel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            goodsName: '',//商品名称
            category_id: '',//分类id
            memberType: props.memberType, //分类arr
            min_price: '',
            max_price: '',
            result_price: ''
        }
    }

    componentDidUpdate() { }

    componentWillReceiveProps = (e) => {//更新父组件传来的值
        this.setState({
            memberType: e.memberType
        })
    }

    shouldComponentUpdate() {
        return true
    }


    componentDidMount = async () => {

    }

    componentWillMount = async () => {

    }

    searchRes = () => {
        let { goodsName, category_id, result_price } = this.state;

        let sqlarr = [
            { name: 'goods_name', val: goodsName },
            { name: 'category_id', val: category_id },
            { name: 'sell_price', val: result_price }
        ]

        this.props.sqlarr(sqlarr)
    }

    //清空
    clearAll = async () => {
        await this.setState({
            goodsName: '',
            category_id: '',
            result_price: '',
            min_price: '',
            max_price: ''
        })
        this.searchRes()
    }

    //搜索类型
    searchType=(type)=>{//'commander团长'，'goods 商品'，'groups 团购'，'orders 订单'


    }

    render() {
        let { goodsName, memberType, min_price, max_price } = this.state

        return (
            <div className='search-left'>

                <div className='flex'>
                    <div className=''>
                        <label className='minWidth80'>商品搜索：</label>
                        <Input value={goodsName} style={{ width: '160px' }}
                            className='input-radius' placeholder='请输入关键词'
                            onChange={(e) => { this.setState({ goodsName: e.target.value }) }} />
                    </div>
                    <div className='' style={{ marginLeft: '20px' }}>
                        <label className='minWidth80'>商品分类：</label>
                        <Select
                            style={{ width: '160px' }}
                            value={this.state.category_id}
                            className='input-radius'
                            showSearch
                            placeholder="请选择"
                            onChange={async (e) => {
                                await this.setState({ category_id: e })
                                this.searchRes()
                            }}

                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {memberType.length ?
                                memberType.map(item => (
                                    <Select.Option key={item.id} lable={item.cate_name} value={item.id}>
                                        {item.cate_name}
                                    </Select.Option>
                                )) : null
                            }
                        </Select>
                    </div>
                    <div className='font12' style={{ marginLeft: '20px' }}>
                        <label className='minWidth80'>商品销售价：</label>
                        <InputNumber
                            min={0}
                            placeholder='最低价格'
                            style={{ width: '120px' }}
                            className='input-radius'
                            value={min_price} onChange={(e) => {
                                this.setState({
                                    min_price: e ? e : '',
                                    result_price: e + '~' + max_price
                                })
                            }} />
                        <span style={{ margin: '0 10px' }}>~</span>
                        <InputNumber
                            min={0}
                            placeholder='最高价格'
                            style={{ width: '120px' }}
                            className='input-radius'
                            value={max_price}
                            onChange={(e) => {
                                this.setState({
                                    max_price: e ? e : '',
                                    result_price: min_price + '~' + e
                                })
                            }} />
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Button className='search-btn' type="primary" onClick={() => { this.searchRes() }}>
                        <SearchOutlined style={{ fontSize: '15px', fontWeight: 'bold' }} />搜索</Button>
                    <span onClick={this.clearAll} className='clear'>清空所有搜索条件</span>
                </div>

            </div>
        )
    }
}