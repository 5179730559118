import React from "react";
import {
    withRouter as router
} from "react-router-dom";
import Base from "../../../components/base";
import {
    Row,
    Col,
    Input,
    DatePicker,
    Space,
    Select,
    Button,
    Tooltip,
    Table,
    Timeline,
    Modal,
    Pagination,
    message,
} from 'antd';
import 'moment/locale/zh-cn';
import qs from '../../../utils/qs';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {
    SearchOutlined
} from '@ant-design/icons';
import AddRemarkRequest from '../api/AddRemarkRequest';
import SendGoodsRequest from '../api/SendGoodsRequest';
import VerifyRequest from '../api/VerifyRequest';
import OrderListRequest from '../api/OrderListRequest';

import Search from "../com/search";
import Print from '../com/print';
import '../css/orderManagement.sass'
//import { now } from "_@types_lodash@4.14.168@@types/lodash";

const {
    RangePicker
} = DatePicker;
const {
    Option
} = Select;

class OrderManagement extends Base {
    constructor(props) {
        const columns = [{
            title: '活动名称',
            width: 100,
            render: (ctx) => {
                //console.log(ctx['group_buy'], )
                return (
                    <div className='outstyle detail' onClick={() => { this.seeDetail(ctx) }}>
                        <Tooltip
                            title={ctx['group_buy']['alpha_group_name']}><span>{ctx['group_buy']['alpha_group_name']}</span></Tooltip>
                        <br />
                        <div
                            style={{ display: 'inline-block' }}
                            className={ctx.order_type == 'PURCHASE_ORDER' ? 'gonghuo group_type' : 'pintuan group_type'}>
                            {
                                ctx.order_type == 'PURCHASE_ORDER' ? '供货订单' : ''
                            }
                            {
                                ctx.order_type == 'USER_ORDER' ? '拼团订单' : ''
                            }
                        </div>
                    </div>
                )
            }
        },
        {
            title: '所属团长',
            width: 80,
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip
                            title={ctx['group_buy']['alpha_name']}><span>{ctx['group_buy']['alpha_name']}</span></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '跟团号/昵称',
            width: 80,
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip
                            title={ctx['group_buy']['alpha_batch_no']}><span>{ctx['group_buy']['alpha_batch_no']}</span></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '用户名',
            width: 70,
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip
                            title={ctx['user']['name']}><span>{ctx['user']['name']}</span></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '用户手机号',
            width: 80,
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip
                            title={qs.hideMobile(ctx['user']['mobile'])}>
                            <span>{qs.hideMobile(ctx['user']['mobile'])}</span>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: '商品信息',
            width: 150,
            render(ctx) {
                return (
                    <div className='shop_detail'>
                        {
                            ctx['order_items'].length ? (
                                ctx['order_items'].map((v, index) => {
                                    return (
                                        <div className={index < ctx['order_items'].length - 1 ? 'areaGoods' : ''}>
                                            <p style={{ color: '#366fbb' }}>商品 {index + 1}</p>
                                            <p>{v['goods_name']} {v['spec_name'] ? (<>{Object.keys(v['spec_name']) + ':' + Object.values(v['spec_name'])}</>) : null}</p>
                                            <p>{Number(v['entity_price']).toFixed(2)}*{v['buy_quantity']}={Number(v['item_total_amount']).toFixed(2)}</p>
                                        </div>
                                    )
                                })
                            ) : null
                        }

                        {
                            ctx['gifts'] && ctx['gifts'].length ? (
                                <>
                                    <p style={{ color: '#366fbb' }}>满赠：</p>
                                    {ctx['gifts'].map((giftLi, index) => {
                                        return <div className={index < ctx['gifts'].length - 1 ? 'areaGoods' : ''}>
                                            <p style={{ color: '#366fbb' }}>赠品 {index + 1}</p>
                                            <Tooltip title={giftLi['show_name'] ? giftLi['show_name'] : giftLi['goods_name'] + '：数量：*' + giftLi['buy_quantity']}>
                                                {qs.ellipsis(giftLi['goods_name'])}
                                                {
                                                    Object.values(giftLi['spec_name']) ? ':' : ''
                                                }
                                                {
                                                    Object.values(giftLi['spec_name'])
                                                }
                                                <span style={{ color: '#366fbb' }}>：数量：*{giftLi['buy_quantity']}</span>
                                            </Tooltip>
                                        </div>

                                    })}

                                </>
                            ) : null
                        }

                    </div>
                )
            }
        },
        {
            title: '订单信息',
            render(ctx) {
                return (
                    // ctx['order_items'].length ? (
                    //     // ctx['order_items'].map(v => {
                    //     //     return (
                    //     //         <div>
                    //     //             <Tooltip title={'¥' + v['item_total_amount']}>
                    //     //                 <p>商品金额：¥{v['item_total_amount']}</p></Tooltip>
                    //     //             {/* <Tooltip title={'¥' + v['item_total_amount']}>
                    //     //                 <p>优惠券金额：¥{v['item_total_amount']}</p></Tooltip> */}
                    //     //             <Tooltip title={'已团' + v['buy_quantity'] + '件'}><p>共计：
                    //     //                     已团{v['buy_quantity']}件</p></Tooltip>
                    //     //         </div>
                    //     //     )
                    //     // })

                    // ) : null
                    <div>
                        <Tooltip title={'¥' + Number(ctx['total_amount']).toFixed(2)}>
                            <p>商品金额：¥{Number(ctx['total_amount']).toFixed(2)}</p></Tooltip>
                        {/* <Tooltip title={'¥' + v['item_total_amount']}>
                                        <p>优惠券金额：¥{v['item_total_amount']}</p></Tooltip> */}
                        <Tooltip title={'已团' + ctx['all_buy_num'] + '件'}><p>共计：
                            已团{ctx['all_buy_num']}件</p></Tooltip>

                    </div>
                )
            }
        },
        {
            title: '物流类型',
            width: 90,
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip title={ctx['delivery_type_text']}><span>{ctx['delivery_type_text']}</span></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '备注',
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip title={ctx['merchant_remark'] ? ctx['merchant_remark'] : '无'}>
                            <p>供应商备注：{ctx['merchant_remark'] ? ctx['merchant_remark'] : '无'}</p></Tooltip>
                        <Tooltip title={ctx['user_remark'] ? ctx['user_remark'] : '无'}>
                            <p>用户备注： {ctx['user_remark'] ? ctx['user_remark'] : '无'}</p></Tooltip>
                        <Tooltip title={ctx['alpha_remark'] ? ctx['alpha_remark'] : '无'}>
                            <p>团长备注： {ctx['alpha_remark'] ? ctx['alpha_remark'] : '无'}</p></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '下单时间',
            width: 100,
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip title={ctx['created_at']}><span>{ctx['created_at']}</span></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '订单状态',
            width: 90,
            render(ctx) {
                return (
                    <div className='outstyle'>
                        <Tooltip title={ctx['order_status_txt']}><span>{ctx['order_status_txt']}</span></Tooltip>
                        <br />
                        {
                            !ctx['need_pay'] ? (
                                <div style={{ display: 'inline', color: '#366FBB' }} className='pintuan group_type'>
                                    线下支付
                                </div>
                            ) : null
                        }
                    </div>
                )
            }
        },
        {
            title: '操作',
            width: 80,
            dataIndex: 'contrl',
            key: 'contrl',
            render: (_, record) =>
                <ul className='contrlBtn flex'>
                    {/* <li onClick={
                        () => {
                            this.seeDetail(record)
                        }
                    }> 查看详情
                    </li> */}
                    {/* {
                        record['order_status'] >=20 && record['order_status']<= 80? (
                            <li onClick={
                                () => {
                                    this.writeoff(record)
                                }
                            } > 核销 </li>
                        ) : null
                    } */}
                    {/* <li onClick={
                        () => {
                            this.refund(record.key)
                        }
                    }> 退款
                        </li> */}
                    <li style={{ color: '#366FBB' }} onClick={
                        () => {
                            this.addRemarks(record)
                        }
                    }> 备注
                    </li>
                    {
                        record['order_status'] === 20 && record['delivery_type'] === 1 ? (
                            <li onClick={
                                () => {
                                    this.sendGoods(record)
                                }
                            }>
                                发货
                            </li>
                        ) : null
                    }
                </ul>
        }
        ];
        super(props)
        this.state = {
            title: '',
            columns: columns,
            orderNum: '',
            visible_add: false,
            visible_Writeoff: false,
            visible_refund: false,
            visible: false,
            visible_refund_reason: false,
            managementId: '',
            setDatesFlag: 'all',
            orderFlag: '',
            dataSource: [],
            merchant_remark: '',
            loading: false,
            sqlStatus: '',
            sqlDate: '',
            sqlName: '',
            goods_name: '',
            total: 0,
            page: 1,
            printData: {},//打印
            sendGoodsVisible: false,
            sendParam: {},// 发货信息
            sendParamNumber: '',//快递单号
            order_id: '',//订单号
            shipping_company: '',//
            writeoffTxt: '',
            writeoffParam: {},
            remark_type: '',
            searchState: false,
            group_name: '',
            group_id: '',
            searchPeople: '',
            searchTeamer: '',
            searchTimeType: 'arrival_date',
            searchTimeArea: '',
            defaultSelectedRowKeys: [],
            exportArr: []
        }
    }

    componentDidMount() {
        //console.log(qs.getPrintDate())
        window.localStorage.setItem('saveObj', [])
    }

    searchList = (page) => {//搜索条件
        let {
            sqlStatus, sqlDate,
            sqlName, goods_name,
            remark_type, group_name,
            order_id, group_id,
            searchPeople, searchTeamer,
            searchTimeType, searchTimeArea
        } = this.state;

        let sqlarr = [
            { name: 'order_status', val: sqlStatus },
            { name: 'created_at', val: sqlDate },
            { name: 'alpha_group_name', val: sqlName },
            { name: 'goods_name', val: goods_name },
            { name: 'remark_type', val: remark_type },
            { name: 'group_name', val: group_name },
            { name: 'order_id', val: order_id },
            { name: 'group_id', val: group_id }
        ]

        if (searchPeople) {
            if (isNaN(searchPeople)) {
                sqlarr.push(
                    { name: 'user_name', val: searchPeople }
                )
            } else {
                sqlarr.push(
                    { name: 'user_mobile', val: searchPeople }
                )
            }
        }

        if (searchTimeArea) {
            sqlarr.push(
                { name: searchTimeType, val: searchTimeArea }
            )
        }

        if (searchTeamer) {
            if (isNaN(searchTeamer)) {
                sqlarr.push(
                    { name: 'alpha_name', val: searchTeamer }
                )
            } else {
                sqlarr.push(
                    { name: 'alpha_phone', val: searchTeamer }
                )
            }
        }

        let sql = qs.sqlSearch(sqlarr)//sql 

        if (!page) {
            page = this.state.page
        }

        this.getOrderList(sql, page)
    }

    getOrderList = async (sql, page) => {//获取订单列表
        this.setState({ loading: true })
        let param = {
            page: page,
            page_size: 30
        }
        if (sql) {
            param['search'] = sql['search'] || ''
        }
        let res = await OrderListRequest.send(param);
        if (!res || !res.status) return this.setState({ loading: false });
        let result = res['data']['data'];

        if (result && result.length) {
            result = result.filter(item => item['order_status'] !== 9)//过滤等待支付完成
            result = result.filter(item => item['order_status'] !== 21)//过滤支付失败
            result = result.filter(item => item['order_status'] !== 100)//过滤未支付取消
            result.forEach(v => {
                v['delivery_type_text'] = qs.logistics(v['delivery_type'])
                v['order_status_txt'] = qs.orderStatus(v['order_status'], v['delivery_type'])

                //综合 件数价格
                v['all_buy_num'] = 0;
                v['all_buy_price'] = 0;

                let items = v['order_items'];
                items.map(j => {
                    v['all_buy_num'] += j['buy_quantity'];
                    v['all_buy_price'] += Number(j['item_total_amount']);
                })
                if (!v['user']) {
                    v['user'] = {
                        avatar: "",
                        desc: null,
                        mobile: "未知",
                        name: "未知",
                        user_id: ""
                    }
                }
            })
        }

        this.setState({
            dataSource: result,
            total: res['data']['total'],
            loading: false,
            page: res['data']['current_page'] ? res['data']['current_page'] : 0
        })

        // this.props.backData({
        //     dataSource: result,
        //     total: res['data']['total'],
        //     loading: false,
        //     page: res['data']['current_page'] ? res['data']['current_page'] : 0
        // })

    }


    WriteoffSave = async () => {
        let { writeoffParam } = this.state
        let hide = message.loading('核销中')
        let res = await VerifyRequest.send(writeoffParam);
        if (res.code === 200) {
            message.success(res.message)
            this.searchList();//////////////////////////////
        } else {
            message.error(res.message)
        }
        hide()
    }

    selectDate = async (data, dateArr) => {//选择日期
        if (!dateArr[0]) {
            await this.setState({
                sqlDate: ''
            })
        } else {
            await this.setState({
                sqlDate: dateArr.join('~')
            })
        }
        this.searchList();///////////////////////////////
    }

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    }

    addRemarks = (e) => {

        let addParam = {
            order_id: e.id
        }
        this.setState({
            visible_add: true,
            visible: true,
            title: '增加备注',
            addParam: addParam
        })

    }

    sendGoods = (e) => {//发货
        this.setState({
            sendGoodsVisible: true,
            order_id: e.id
        })
    }

    sendGoodsCom = async () => {//确定发货
        const { order_id, sendParamNumber, shipping_company } = this.state
        const hide = message.loading('发货中')
        let param = {
            order_id: order_id,
            shipping_no: sendParamNumber,
            shipping_company
        }
        let res = await SendGoodsRequest.send(param);
        if (res.code === 200) {
            message.success('发货成功！')
            this.setState({
                sendGoodsVisible: false
            })
            this.searchList();////////////////////////
        } else {
            message.error(res.message)
        }
        hide();
    }

    addRemarksHandle = async (param) => {
        const hide = message.loading('添加备注中')
        let res = await AddRemarkRequest.send(param)
        if (res.status) {
            message.success('添加成功！')
        } else {
            message.error(res.message)
        }
        hide()
        this.handleCancel()
        this.searchList()//获取订单列表///////////////////////////
    }

    handleSubmitAdd = () => {
        let { addParam, merchant_remark } = this.state;
        addParam['merchant_remark'] = merchant_remark
        this.addRemarksHandle(addParam)
    }

    refund = (key) => {
        this.setState({
            visible_refund: true,
            visible: true,
            title: '待退款'
        })
    }

    writeoff = async (param) => {
        //console.log(param)
        let writeoffTxt = ''
        let arr = param['order_items'];
        arr.map(v => {
            writeoffTxt += v['goods_name'] + v['buy_quantity'] + '件 ' + ' '
        })
        //console.log(writeoffTxt)
        await this.setState({
            writeoffTxt,
            writeoffParam: {
                order_ids: [param['id']],
                user_id: param['user_id']
            }
        })
        this.setState({
            visible_Writeoff: true,
            visible: true,
            title: '提示'
        })
    }

    handleCancel = (e) => {
        this.setState({
            visible_Writeoff: false,
            visible_add: false,
            visible: false,
            visible_refund: false,
            visible_refund_reason: false,
            sendGoodsVisible: false
        })

        //条件判断
        if (e === 'refund') {
            this.setState({
                title: '拒绝理由',
                visible: true,
                visible_refund_reason: true
            })
        }
    }

    handleSubmit = () => {
        this.handleCancel()
    }

    getOrderNum = (e) => {
        this.setState({
            orderNum: e.target.defaultValue
        })
        //console.log(e.target.defaultValue)
    }

    setDates = async (index) => {
        if (index == 'all') {
            await this.setState({
                sqlDate: '',
                setDatesFlag: index
            })
            return this.searchList()///////////////////////////////
        }

        let fontSort = index * 24 * 60 * 60 * 1000;

        let nowSort = new Date().getTime();

        let resultDate

        let beginDate = qs.betweenDate(nowSort - fontSort)

        if (index == 7) {
            let endDate = qs.betweenDate(nowSort);

            resultDate = beginDate + '~' + endDate

        } else {
            resultDate = beginDate
        }

        await this.setState({
            setDatesFlag: index,
            sqlDate: resultDate
        })

        this.searchList()//////////////////////
    }


    seeDetail = (e) => {
        this.props.history.push({
            pathname: '/orders/orderDetail',
            state: {
                id: e.id,
                detail: e
            }
        })
    }

    clearAll = async () => {//清空所有条件
        await this.setState({
            goods_name: '',
            sqlName: ''
        })
        this.searchList()//////////////////////////
    }

    printHandle = (type) => {//批量打印
        let { printData, end_date_print } = this.state
        printData = {
            title: '选择时间',
            visible: true,
            type: type,//1打印，，2导出
            text: '选择打印的订单类型'
        }
        this.setState({
            printData: printData
        })
    }


    onSelectChange = async (selectedRowKeys, arr) => {//
        await this.setState({ selectedRowKeys });
        //console.log(selectedRowKeys, arr)
        let { page, defaultSelectedRowKeys } = this.state;
        page = page - 1;
        //let saveObj = window.localStorage.getItem('saveObj') || []
        let saveObj = []
        saveObj = typeof (saveObj) == 'string' ? JSON.parse(saveObj) : saveObj;
        let arrItem = [];
        let arrTtemTotal = 0;
        arr.map(v => {
            v['order_items'].map(item => arrTtemTotal += item['buy_quantity'])
            arrItem.push({
                id: v['id'],//id
                name: v['group_buy']['alpha_group_name'],//name
                user: v['user'],//用户信息
                gifts: v['gifts'],
                order_items: v['order_items'],
                order_mailing: v['order_mailing'],
                arrTtemTotal,
                total_amount: v['total_amount'],
                user_remark: v['user_remark'],
                created_at: v['created_at']
            })
        })
        saveObj[page] = arrItem;
        for (let i in saveObj) {
            for (let j in saveObj[i]) {
                //console.log(saveObj[i][j].id, 'mmmmmmmmmmmmm')
                defaultSelectedRowKeys = defaultSelectedRowKeys.concat(saveObj[i][j].id)
            }
        }
        await this.setState({
            defaultSelectedRowKeys: defaultSelectedRowKeys.filter((x, index, self) => self.indexOf(x) === index),
            exportArr: arrItem
        })

        //window.localStorage.setItem('saveObj', JSON.stringify(saveObj))

        //console.log(this.state.defaultSelectedRowKeys,)

    }

    render() {
        let { printData, goods_name, sqlName, searchState, orderFlag, page, total, defaultSelectedRowKeys, exportArr } = this.state;
        // rowSelection object indicates the need for row selection
        let rowSelection = {
            //selectedRowKeys: defaultSelectedRowKeys,
            defaultSelectedRowKeys,
            onChange: this.onSelectChange
        };
        return (<div className='orderManagement' style={{ position: 'relative' }}>
            { /* 订单管理 */}

            {/* 打印 */}
            <Print printData={printData} jump={(path, param) => { this.props.history.push({ pathname: path, state: param }) }}></Print>

            {/* 搜索 */}
            <Search
                backTicket={e => { this.props.history.push({ pathname: 'receipt', state: e }) }}
                jumpReceipt={e => { this.props.history.push({ pathname: e, state: { exportArr } }) }}
                jumpUrl={e => { this.props.history.push(e) }}
                clearExport={e => { this.setState({ exportArr: e }) }}
                exportArr={exportArr}
                searchParam
                jump={(path, param) => { this.props.history.push({ pathname: path, state: param }) }}
                backData={e => {
                    this.setState({ ...e })
                }} />


            { /* 切换条件 */}

            { /* lists数据 */}
            <div className="lists">
                <Table
                    multiple={true}
                    rowSelection={rowSelection}
                    dataSource={
                        this.state.dataSource
                    }
                    loading={
                        this.state.loading
                    }
                    key={
                        record => record.id
                    }
                    rowKey={
                        record => record.id
                    }
                    columns={
                        this.state.columns
                    }
                    rowClassName={
                        (e, index) => {
                            return index % 2 ? 'gary' : ''
                        }
                    }

                    pagination={false}
                    scroll={
                        {
                            scrollToFirstRowOnChange: false,
                            x: false,
                            y: 500
                        }
                    }
                />
            </div>

            <div className={'page_style'}>
                <Pagination
                    onChange={(e) => {
                        this.setState({ page: e })
                        this.searchList(e)//////////////////////////////////
                    }}
                    hideOnSinglePage={true}
                    defaultCurrent={1}
                    showSizeChanger={false}
                    pageSize={30}
                    current={page}
                    total={total} />
            </div>

            <Modal title={
                this.state.title
            }
                centered style={
                    {
                        textAlign: 'center'
                    }
                }
                visible={
                    this.state.visible
                }
                onOk={
                    () => {
                    }
                }
                onCancel={
                    this.handleCancel
                }
                footer={
                    <div style={
                        {
                            textAlign: 'center'
                        }
                    }> {this.state.visible_add ? (
                        <div>
                            <Button size="small"
                                className='button-bac'
                                onClick={
                                    this.handleCancel
                                }
                                type="default"> 取消 </Button>
                            <Button size="small"
                                className='button-bac'
                                onClick={
                                    this.handleSubmitAdd
                                }
                                type="primary"> 保存 </Button>
                        </div>
                    ) : null
                        }

                        {this.state.visible_Writeoff ? (
                            <div>
                                <Button size="small"
                                    className='button-bac'
                                    onClick={
                                        this.handleCancel
                                    }
                                    type="default"> 取消 </Button>
                                <Button size="small"
                                    className='button-bac'
                                    onClick={
                                        this.WriteoffSave
                                    }
                                    type="primary"> 保存 </Button>
                            </div>
                        ) : null
                        }

                        {this.state.visible_refund ? (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    style={{ marginRight: '40px' }}
                                    size='small'
                                    className='button-bac refundcancelBtn'
                                    onClick={
                                        () => this.handleCancel('refund')
                                    }>
                                    拒绝
                                </Button>
                                <Button size='small'
                                    className='button-bac'
                                    onClick={
                                        this.handleSubmit
                                    }>
                                    同意
                                </Button>
                                {/* <p style={
                                        {
                                            display: 'inline-block',
                                            color: '#CCCCCC',
                                            marginLeft: '24px',
                                            cursor: 'pointer'
                                        }
                                    }
                                        onClick={
                                            this.handleCancel
                                        }> 取消， 考虑一下~ </p> */}
                            </div>
                        ) : null
                        }

                        {
                            this.state.visible_refund_reason ? (
                                <div>
                                    <Button size='small' className='button-bac dark-row'
                                        onClick={this.handleCancel}>取消</Button>
                                    <Button size='small' className='button-bac'>确定</Button>
                                </div>
                            ) : null
                        }

                    </div>
                }>

                {<div
                    className=''
                    style={
                        {
                            textAlign: 'left'
                        }
                    }> {
                        this.state.visible_add ? (
                            <div className='flex' style={{ margin: '20px 0' }}>
                                <label style={{ display: 'inline-block', lineHeight: '32px', whiteSpace: 'nowrap' }}>
                                    添加备注：
                                </label>

                                <Input style={{ borderRadius: '4px' }}
                                    onChange={(e) => {
                                        //console.log(e);
                                        this.setState({ merchant_remark: e.target.value })
                                    }}
                                    placeholder='添加备注'>
                                </Input>
                            </div>
                        ) : null
                    }

                    {
                        this.state.visible_Writeoff ? (
                            <p> 确定核销：{this.state.writeoffTxt} 吗？ </p>
                        ) : null
                    }

                    {this.state.visible_refund ? (
                        <Timeline>
                            <Timeline.Item color="green">
                                <div className='refund_reason'>
                                    <p> 用户昵称： 安之若素 </p>
                                    <p> 退款金额：￥ 1.00 </p>
                                    <p> 申请说明： 这个东西不想要啦！ </p>
                                </div>
                            </Timeline.Item>
                        </Timeline>
                    ) : null
                    }

                    {
                        this.state.visible_refund_reason ? (
                            <div className='flex' style={{ flexWrap: 'nowrap', marginBottom: '20px' }}>
                                <label style={{ whiteSpace: 'nowrap', paddingRight: '10px' }}>拒绝说明：</label>
                                <Input style={{ width: '470px' }} placeholder='请输入拒绝理由' maxLength={20}></Input>
                            </div>
                        ) : null
                    }
                </div>}

            </Modal>

            <Modal
                centered
                //发货
                visible={this.state.sendGoodsVisible}
                footer={false}
                title='发货'
                onCancel={this.handleCancel}
            >
                <div className='sendGoods'>
                    <div className='line'>
                        <p>
                            <label>
                                请输入快递公司
                            </label>
                            <Input placeholder='请输入快递公司' onChange={(e) => { this.setState({ shipping_company: e.target.value }) }}></Input>
                        </p>

                        <p>
                            <label>
                                请输入快递单号
                            </label>
                            <Input placeholder='请输入快递单号' onChange={(e) => {
                                this.setState({ sendParamNumber: e.target.value })
                            }}></Input>
                        </p>

                    </div>
                    <div className='flex' style={{ justifyContent: 'center' }}>
                        <Button size="small"
                            className='button-bac'
                            style={{ marginRight: '10px' }}
                            onClick={
                                this.handleCancel
                            }
                            type="default"> 取消 </Button>
                        <Button size="small"
                            className='button-bac'
                            onClick={
                                this.sendGoodsCom
                            }
                            type="primary"> 确定 </Button>
                    </div>
                </div>

            </Modal>


        </div>
        )
    }
}

export default router(OrderManagement);
